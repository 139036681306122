import { Dispatch, SetStateAction } from 'react';

export enum ROUTE {
  HOME = 'home',
  MOBILE_PHONES = 'phones',
  POPULAR_PHONES = 'popular-phones',
  UPCOMING_PHONES = 'upcoming-phones',
  UPCOMING_TECHNOLOGIES = 'blog/category/upcoming-technologies',
  COMPARE = 'compare-phones',
  BLOG = 'blog',
  SEARCH = 'search',
  BRAND = 'brand',
  PRIVACY_POLICY = 'privacy-policy',
  TERMS = 'terms',
  ABOUT_US = 'about-us',
  CONTACT_US = 'contact-us',
  ERROR_PAGE = '/404',
}

export enum BREADCRUMB {
  HOME = 'Home',
  MOBILE_PHONES = 'Mobile Phones',
  POPULAR_PHONES = 'Popular Phones',
  UPCOMING_PHONES = 'Upcoming Phones',
  COMPARE = 'Compare Phones',
  BLOG = 'blog',
  PRIVACY_POLICY = 'Privacy and Policy',
  TERMS = 'Terms and Conditions',
  ABOUT_US = 'About Us',
  CONTACT_US = 'Contact Us',
}

export enum Tag {
  phone = 'phone',
  watch = 'watch',
  tablet = 'tablet',
  upcoming = 'upcoming',
  popular = 'popular',
  featured = 'featured',
}
export enum storageKey {
  compareRouteLink = 'kcjEw9A3KqrffzhtYJoQ',
  brands = 'kUH74&*hfae&WDcjef&W9e',
}

export interface ContactUsType {
  data: {
    username: string;
    email: string;
    description: string;
  };
}

export interface LabelMap {
  [key: string]: string[];
}
export interface productDataInterface {
  id: number;
  productName: string;
  productImage: string;
  price: string;
  scoreTag: string;
  brandName: string;
  category: string;
}
export interface selectedBrand {
  brandName: string;
  isSelected: boolean;
}
export interface TextFieldObject {
  text: string;
  slug: string;
  isRemove: boolean;
  data: any;
  id: number | null;
}
export interface pagination {
  page: number;
  pageCount: number;
  pageSize: number;
  total: number;
}
export interface searchCompareText {
  text: string;
  slug: string;
  isRemove: boolean;
  data: never[];
  id: null;
  deviceName: string;
}
interface compareData {
  name: string;
}
interface compareItem {
  key: string;
  value: null | string | boolean | compareData[];
}

export interface compareTableData {
  key: string;
  data: compareItem[][];
}

export interface breadCrumbType {
  hrefLink: string;
  title: string;
  isActive: boolean;
}

export interface NextAppContextInterface {
  setInputValue: Dispatch<SetStateAction<string>>;
  inputValue: string;
  setSearchHeaderValue: Dispatch<SetStateAction<string>>;
  searchHeaderValue: string;
  setIsApiCall: Dispatch<SetStateAction<boolean>>;
  isApiCall: boolean;
  tagArr: { label: string; value: string | number; match: string | number }[];
  setTagArr: Dispatch<
    SetStateAction<
      { label: string; value: string | number; match: string | number }[]
    >
  >;
  comparisonSearch: any;
  setComparisonSearch: Dispatch<SetStateAction<any[]>>;
  setIsChange: Dispatch<SetStateAction<boolean>>;
  isChange: boolean;
  setIsClickedOnCompare: Dispatch<SetStateAction<boolean>>;
  isClickedOnCompare: boolean;
  scrollTargetRef: HTMLDivElement | null | any;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  searchQuery: string;
  setBreadCrumbs: Dispatch<SetStateAction<breadCrumbType[] | undefined>>;
  breadCrumb: breadCrumbType[] | undefined;
  currencyDropDownValue: string;
  setCurrencyDropDownValue: Dispatch<SetStateAction<string>>;
  isCurrencyCodeDropDownChange: boolean;
  setCurrencyDropDownChange: Dispatch<SetStateAction<boolean>>;
  isSmallDevice: boolean;
  setIsSmallDevice: Dispatch<SetStateAction<boolean>>;
  deviceName: string[];
  setDeviceName: Dispatch<SetStateAction<string[]>>;
  deviceTitle: any;
  setDeviceTitle: any;
  isModelOpen: boolean;
  setIsModelOpen: Dispatch<SetStateAction<boolean>>;
}
export interface MetaDescriptionInterface {
  title?: string;
  description?: string;
  url?: string;
  ogUrl?: string;
  ogType?: string;
  imageURL?: string | any;
  isNotForHomePage?: boolean;
  isForSingleDevicePage?: boolean;
  robots?: string | undefined;
  keywords?: string;
  canonical?: string;
  country?: string;
}

export interface SeoInfo {
  canonicalURL: null | string;
  id: number;
  keywords: string;
  metaDescription: string;
  metaImage: null | string;
  metaRobots: string | null;
  metaTitle: string | null;
  metaViewport: string | null;
  structuredData: string | null;
}
export interface ImageData {
  alternativeText: string | null;
  caption: string | null;
  createdAt: string | null;
  ext: string | null;
  folderPath: string | null;
  hash: string | null;
  height: number | null;
  id: number | null;
  mime: string | null;
  name: string | null;
  previewUrl: string | null;
  provider: string | null;
  provider_metadata: string | null;
  size: number | null;
  updatedAt: string | null;
  url: string | null;
  width: number | null;
}

export interface ContactUsForm {
  username: string;
  email: string;
  description?: string;
}

export interface ContactUsFormError {
  invalidCaptchaToken: string;
  someThingWrong: string;
}

export enum DeviceTags {
  POPULAR_BADGE = 'popular_badge',
  UPCOMING_BADGE = 'upcoming_badge',
  DISCONTINUE_BADGE = 'discontinue_badge',
  RUMORED_BADGE = 'rumored_badge',
}

export enum DisplayTagNames {
  POPULAR = 'Popular',
  UPCOMING = 'Upcoming',
  DISCONTINUE = 'Discontinue',
  RUMORED = 'Rumored',
}

export enum priceEnum {
  US = 'USD',
  IN = 'INR',
  BR = 'BRL',
  CA = 'CAD',
  EU = 'EUR',
  GB = 'GBP',
}
