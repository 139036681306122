import React from 'react';

const GoneComponent = () => {
  return (
    <div>
      <h1>Gone</h1>
      <p>
        The requested resource is no longer available on this server and there
        is no forwarding address.
      </p>
      <p>Please remove all references to this resource.</p>
    </div>
  );
};

export default GoneComponent;
