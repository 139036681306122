import { BREADCRUMB, ROUTE } from '@/Interface/interface';

const breadCrumbConst = {
  mobilePhones: {
    hrefLink: `/${ROUTE.MOBILE_PHONES}`,
    title: BREADCRUMB.MOBILE_PHONES,
  },
  popularPhones: {
    hrefLink: `/${ROUTE.POPULAR_PHONES}`,
    title: BREADCRUMB.POPULAR_PHONES,
  },
  featuredPhones: {
    hrefLink: `/${ROUTE.UPCOMING_PHONES}`,
    title: BREADCRUMB.UPCOMING_PHONES,
  },
  comparePhones: {
    hrefLink: `/${ROUTE.COMPARE}`,
    title: BREADCRUMB.COMPARE,
  },
  contactUS: {
    hrefLink: `/${ROUTE.CONTACT_US}`,
    title: BREADCRUMB.CONTACT_US,
  },
  privacyPolicy: {
    hrefLink: `/${ROUTE.PRIVACY_POLICY}`,
    title: BREADCRUMB.PRIVACY_POLICY,
  },
  terms: {
    hrefLink: `/${ROUTE.TERMS}`,
    title: BREADCRUMB.TERMS,
  },
  aboutUS: {
    hrefLink: `/${ROUTE.ABOUT_US}`,
    title: BREADCRUMB.ABOUT_US,
  },
  blog: {
    hrefLink: `/${ROUTE.BLOG}`,
    title: BREADCRUMB.BLOG,
  },
};

export default breadCrumbConst;
