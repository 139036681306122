import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';
const themeCSS = {
  bodyFontFamily: 'var(--fontFamily)',
  bodyFontWeight: '400',
  baseFont: 'var(--baseFont)',
};
const Theme = createTheme({
  palette: {
    primary: {
      main: '#0063D1',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: themeCSS.bodyFontFamily,
          fontWeight: themeCSS.bodyFontWeight,
          letterSpacing: '0',
          lineHeight: 1.7,
          '&.MuiTypography-body1, &.MuiTypography-h2, &.MuiTypography-h3': {
            fontFamily: themeCSS.bodyFontFamily,
          },
          '&.MuiLink-root , .MuiLink-root': {
            color: 'var(--black)',
            textDecoration: 'none',
            '&:hover': {
              color: 'var(--blue)',
            },
          },
          '@media (max-width:900px)': {
            fontSize: '14px',
            lineHeight: '22px',
          },
          '@media (max-width:600px)': {
            fontSize: '12px',
            lineHeight: '20px',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '15px',
          paddingRight: '15px',
          '@media (min-width:600px)': {
            paddingLeft: '15px',
            paddingRight: '15px',
          },
        },
      },
    },
    MuiMenuList: {
      styleOverrides: {
        root: {
          maxHeight: '10px ',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: themeCSS.baseFont,
          fontFamily: themeCSS.bodyFontFamily,
          boxShadow: 'none',
          letterSpacing: 0,
          textTransform: 'capitalize',
          fontWeight: 600,
          minWidth: '124px',
          padding: '8px 25px',
          borderRadius: '8px',
          '&:hover': {
            opacity: 0.8,
            boxShadow: 'none',
          },
          '&.MuiButton-contained': {
            background: 'var(--blue)',
            color: 'var(--white)',
            border: '1px solid var(--blue)',
            '&:hover': {
              background: 'var(--blue)',
            },
            '&:disabled': {
              opacity: '0.3',
              backgroundColor: '#D10000 !important',
            },
          },
          '&.MuiButton-outlined': {
            borderColor: 'var(--blue)',
            color: 'var(--blue)',
          },
          '@media (max-width:599px)': {
            minWidth: '105px',
            padding: '6px 10px',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: themeCSS.baseFont,
          fontFamily: themeCSS.bodyFontFamily,
          letterSpacing: 0,
          '& input::placeholder': {
            opacity: '0.6',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& .MuiPagination-ul': {
            justifyContent: 'center',
            '& .MuiButtonBase-root': {
              background: '#F1F1F1',
              fontSize: themeCSS.baseFont,
              fontFamily: themeCSS.bodyFontFamily,
            },
            '& .MuiButtonBase-root.Mui-selected': {
              background: 'var(--blue)',
              color: 'var(--white)',
            },
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          position: 'relative',
          '& .MuiSlider-thumb': {
            background: 'var(--white)',
            border: '5px solid var(--blue)',
          },
          '& .MuiSlider-valueLabelOpen': {
            zIndex: '1',
            // , transform: 'translate(0, 25%)'
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          width: '100%',
          borderCollapse: 'collapse',
          border: 'none',
          fontSize: themeCSS.baseFont,
          fontFamily: themeCSS.bodyFontFamily,
          '& .td-images': {
            width: '230px',
            border: '1px solid #D8D8D8',
            textAlign: 'center',
            textWrap: 'wrap',
            '@media (max-width:600px)': {
              padding: '8px 15px',
            },
          },
          '& .td-text-row': {
            whiteSpace: 'break-spaces !important',
            width: '230px',
            border: '1px solid #D8D8D8',
            borderTop: 'none',
            textAlign: 'left',
            textWrap: 'wrap',
            borderBottom: 'none',
            wordBreak: 'break-all',
            '@media (min-width:1200px)': {
              wordBreak: 'break-all',
            },
            '@media (max-width:600px)': {
              padding: '8px 15px',
            },
          },
          '& .MuiTableRow-root:last-child td': {
            // borderBottom: "none",
            // borderRight: "none",
          },
          '& .MuiTableRow-root': {
            verticalAlign: 'baseline',
            // textWrap: "nowrap",
          },
          '& .MuiTableCell-root': {
            // fontFamily: 'var( --fontFamily)',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
        },
      },
    },
    MuiCollapse: {
      styleOverrides: {
        root: {
          '& .MuiCollapse-wrapperInner': {
            height: 'auto',
            // overflow:"auto"
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&.MuiTextField-root': {
            border: 'none !important',
          },
        },
      },
    },
  },
});
export default Theme;
