import React, { useState, useEffect, useContext } from 'react';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { Context } from '@/pages/_app';
import { scrollToElement } from '@/Configuration/Service';
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { scrollTargetRef } = useContext(Context);
  let isBrowser = typeof window !== 'undefined';
  let body = isBrowser ? document.querySelector('body') : null;
  useEffect(() => {
    body?.addEventListener('scroll', (event) => {
      if (body?.scrollTop === 0) {
        setIsVisible(false);
      } else {
        setIsVisible(true);
      }
    });
  }, []);

  const scrollToTop = () => {
    if (body)
      body.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
  };

  return (
    <div>
      {isVisible ? (
        <button
          className="scroll-to-top-button"
          onClick={() => {
            scrollToTop();
          }}
        >
          <ArrowUpwardIcon />
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default ScrollToTopButton;
