export const CountryList = {
  'Asia/Calcutta': 'IN',
  'Asia/Kolkata': 'IN',

  'Europe/London': 'GB',

  //   brazil

  'America/Araguaina': 'BR',
  'America/Bahia': 'BR',
  'America/Belem': 'BR',
  'America/Boa_Vista': 'BR',
  'America/Campo_Grande': 'BR',
  'America/Cuiaba': 'BR',
  'America/Eirunepe': 'BR',
  'America/Fortaleza': 'BR',
  'America/Maceio': 'BR',
  'America/Manaus': 'BR',
  'America/Noronha': 'BR',
  'America/Porto_Velho': 'BR',
  'America/Recife': 'BR',
  'America/Rio_Branco': 'BR',
  'America/Santarem': 'BR',
  'America/Sao_Paulo': 'BR',

  //   canada

  'America/Cambridge_Bay': 'CA',
  'America/Dawson': 'CA',
  'America/Dawson_Creek': 'CA',
  'America/Edmonton': 'CA',
  'America/Fort_Nelson': 'CA',
  'America/Glace_Bay': 'CA',
  'America/Goose_Bay': 'CA',
  'America/Halifax': 'CA',
  'America/Inuvik': 'CA',
  'America/Iqaluit': 'CA',
  'America/Moncton': 'CA',
  'America/Panama': 'CA',
  'America/Phoenix': 'CA',
  'America/Puerto_Rico': 'CA',
  'America/Rankin_Inlet': 'CA',
  'America/Regina': 'CA',
  'America/Resolute': 'CA',
  'America/St_Johns': 'CA',
  'America/Swift_Current': 'CA',
  'America/Toronto': 'CA',
  'America/Vancouver': 'CA',
  'America/Whitehorse': 'CA',
  'America/Winnipeg': 'CA',

  //   europe
  'Europe/Amsterdam': 'EU',
  'Europe/Andorra': 'EU',
  'Europe/Astrakhan': 'EU',
  'Europe/Athens': 'EU',
  'Europe/Belgrade': 'EU',
  'Europe/Berlin': 'EU',
  'Europe/Bratislava': 'EU',
  'Europe/Brussels': 'EU',
  'Europe/Bucharest': 'EU',
  'Europe/Budapest': 'EU',
  'Europe/Busingen': 'EU',
  'Europe/Chisinau': 'EU',
  'Europe/Copenhagen': 'EU',
  'Europe/Dublin': 'EU',
  'Europe/Gibraltar': 'EU',
  'Europe/Guernsey': 'EU',
  'Europe/Helsinki': 'EU',
  'Europe/Isle_of_Man': 'EU',
  'Europe/Istanbul': 'EU',
  'Europe/Jersey': 'EU',
  'Europe/Kaliningrad': 'EU',
  'Europe/Kiev': 'EU',
  'Europe/Kirov': 'EU',
  'Europe/Lisbon': 'EU',
  'Europe/Ljubljana': 'EU',
  'Europe/Luxembourg': 'EU',
  'Europe/Madrid': 'EU',
  'Europe/Malta': 'EU',
  'Europe/Mariehamn': 'EU',
  'Europe/Minsk': 'EU',
  'Europe/Monaco': 'EU',
  'Europe/Moscow': 'EU',
  'Europe/Oslo': 'EU',
  'Europe/Paris': 'EU',
  'Europe/Podgorica': 'EU',
  'Europe/Prague': 'EU',
  'Europe/Riga': 'EU',
  'Europe/Rome': 'EU',
  'Europe/Samara': 'EU',
  'Europe/San_Marino': 'EU',
  'Europe/Sarajevo': 'EU',
  'Europe/Saratov': 'EU',
  'Europe/Simferopol': 'EU',
  'Europe/Skopje': 'EU',
  'Europe/Sofia': 'EU',
  'Europe/Stockholm': 'EU',
  'Europe/Tallinn': 'EU',
  'Europe/Tirane': 'EU',
  'Europe/Ulyanovsk': 'EU',
  'Europe/Uzhgorod': 'EU',
  'Europe/Vaduz': 'EU',
  'Europe/Vatican': 'EU',
  'Europe/Vienna': 'EU',
  'Europe/Vilnius': 'EU',
  'Europe/Volgograd': 'EU',
  'Europe/Warsaw': 'EU',
  'Europe/Zagreb': 'EU',
  'Europe/Zaporozhye': 'EU',
  'Europe/Zurich': 'EU',

  //   other
  'Africa/Abidjan': 'EU',
};
