import Autocomplete from '@mui/material/Autocomplete';
import { Box, TextField, Button, Grid } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { Context } from '@/pages/_app';
import { getSearchDevicesAPI } from '@/Configuration/API';
import Image from 'next/image';
import defaultSmartPhone from '../Assets/Images/defaultSmartphone.jpg';
import { ROUTE } from '@/Interface/interface';
import { useRouter } from 'next/router';
function HeaderSearch(props: { setOpen: Function | null; countryCode: string }) {
  const { setOpen, countryCode } = props;
  const router = useRouter();
  const { setIsChange, setSearchQuery, setInputValue } = useContext(Context);
  const { searchHeaderValue, setSearchHeaderValue } = useContext(Context);
  const [searchData, setSearchData] = useState<any>([]);
  const [isArrowKeyDown, setIsArrowKeyDown] = useState(false);

  const getResult = async () => {
    const object = {
      pagination: {
        page: '',
        pageSize: '',
      },
      search: searchHeaderValue !== '' ? searchHeaderValue : null,
      brand_name: null,
      internal_storage: null,
      battery_capacity: null,
      display_screen_size: null,
      primary_camera: null,
      secondary_camera: null,
      chipset_processor: null,
      resolution_type: null,
    };
    const result = await getSearchDevicesAPI(
      JSON.stringify(object),countryCode
    );
    //getSearchDevicesAPI
    if (result?.data?.data) {
      setSearchData(result?.data?.data);
    } else {
      setSearchData([]);
    }
  };

  useEffect(() => {
    if (searchHeaderValue !== '') {
      // Use debounce to delay search API call
      const delayDebounceFn = setTimeout(async () => {
        getResult();
      }, 500);
      return () => clearTimeout(delayDebounceFn);
    }
  }, [searchHeaderValue]);

  const clearSearch = () => {
    setSearchData([]);
    setSearchHeaderValue('');
  };

  const closeSearch = () => {
    if (setOpen !== null) {
      setOpen(false);
    }
  };

  return (
    <Grid display="flex" justifyContent="end">
      <Autocomplete
        autoFocus
        freeSolo
        id="size-small-standard"
        classes={{
          paper: 'header-paper',
        }}
        sx={{
          width: { xs: '100%', md: '300px' },
          '&.MuiAutocomplete-root': {
            padding: '0px !important',
            border: '2px solid #D8D8D8 !important',
          },
        }}
        value={searchData}
        inputValue={searchHeaderValue}
        options={searchData}
        getOptionLabel={(option) =>
          option.device_name ||
          option.brand_name ||
          option.internal_storage ||
          option.chipset_processor ||
          option.platform_gpu_series ||
          option.battery_type ||
          option.body_build_glass_back ||
          option.display_type ||
          option.id ||
          searchHeaderValue ||
          ''
        }
        filterOptions={(options) => {
          if (searchData.length) {
            return options?.filter((option) => {
              const searchTerms = Object.values(option)
                ?.join(' ')
                .toLowerCase();
              return searchTerms;
            });
          } else {
            return [];
          }
        }}
        onInputChange={(_, newInputValue) => {
          setSearchHeaderValue(newInputValue as string);
        }}
        onKeyUp={(e: React.KeyboardEvent<HTMLDivElement>) => {
          if (e.key === 'Enter') {
            clearSearch();
            (e.target as HTMLInputElement).blur();
            clearSearch();
            const selectedOption = searchData.find(
              (res: { device_name: string }) => {
                return res.device_name === searchHeaderValue;
              },
            );
            if (searchHeaderValue !== '') {
              if (selectedOption && selectedOption.slug) {
                router.push(`/${selectedOption.slug}/`);
                closeSearch();
              } else {
                setIsArrowKeyDown(false);
                setIsChange(true);
                setInputValue((e.target as HTMLInputElement).value as string);
                setSearchQuery((e.target as HTMLInputElement).value as string);
                closeSearch();
                if (
                  router.pathname !== `/${ROUTE.MOBILE_PHONES}/[[...phonesId]]`
                ) {
                  router.push(
                    `/${ROUTE.MOBILE_PHONES}/?q=${searchHeaderValue}`,
                  );
                }
              }
            }
          } else {
            if (e.key === 'ArrowDown') {
              setIsArrowKeyDown(true);
            }
          }
        }}
        onFocus={() => {
          if (searchHeaderValue === '') {
            getResult();
          }
        }}
        renderOption={(props, option) => (
          <Box
            component="li"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            onMouseDown={() => {
              clearSearch();
              router.push(`/${option.slug}`);
              closeSearch();
            }}
            onFocusCapture={() => {
              clearSearch();
              router.push(`/${option.slug}`);
              closeSearch();
            }}
          >
            {option?.main_image?.url !== null &&
            option?.main_image?.url !== undefined ? (
              <Image
                loading="lazy"
                width={0}
                height={0}
                sizes="100vw"
                src={`${process.env.API_BASE_URL}${option?.main_image?.url}`}
                alt={
                  option?.main_image?.alternativeText !== null
                    ? `${option?.main_image?.alternativeText}`
                    : 'brand-image'
                }
                style={{ width: '25px', height: '25px', objectFit: 'contain' }}
              />
            ) : (
              <Image
                loading="lazy"
                width={0}
                height={0}
                sizes="100vw"
                src={defaultSmartPhone}
                alt="brand-image"
                style={{ width: '25px', height: '25px', objectFit: 'contain' }}
              />
            )}
            {option?.device_name}
          </Box>
        )}
        renderInput={(params) => (
          <Box
            className="search-form"
            display={'flex'}
            alignItems={'center'}
            sx={{
              '& .MuiButtonBase-root': {
                display: 'none',
              },
            }}
          >
            <TextField
              placeholder="Search"
              {...params}
              InputProps={{
                ...params.InputProps,
                type: 'text',
              }}
              sx={{
                borderRadius: '5px 0px 0px 5px',
                border: '2px solid #EBEBEB !important',
                '& input': {
                  padding: '13px 20px',
                  borderRadius: '5px 0px 0px 5px',
                },
                '& fieldset': {
                  opacity: 0,
                },
              }}
            />
          </Box>
        )}
      />
      <Button
        variant="contained"
        className=""
        sx={{
          borderRadius: '0 5px 5px 0',
          padding: '11.5px 32px !important',
          minWidth: 'auto',
        }}
        onClick={() => {
          clearSearch();
          if (searchHeaderValue.trim() !== '') {
            const selectedOption = searchData.find(
              (res: { device_name: string }) => {
                return res.device_name === searchHeaderValue;
              },
            );
            if (selectedOption && selectedOption.slug) {
              router.push(`/${selectedOption.slug}/`);
              closeSearch();
            } else {
              setSearchQuery(searchHeaderValue);
              setIsChange(true);
              if (router.pathname !== '/phones') {
                router.push(`/${ROUTE.MOBILE_PHONES}/?q=${searchHeaderValue}`);
              }
              closeSearch();
            }
          }
        }}
      >
        search
      </Button>
    </Grid>
  );
}
export default HeaderSearch;
