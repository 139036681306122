const FlagSvg = (props: { id: number; country: string; code: string }) => {
  return (
    <>
      {props.id === 0 ? (
        //us flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clipPath="url(#clip0_1853_98)">
            <path
              d="M7.66892 5.4511C7.19802 5.19086 6.69739 4.99259 6.20171 4.78689C5.91421 4.66792 5.63911 4.52913 5.39623 4.33582C4.9179 3.95414 5.0096 3.33454 5.56972 3.08918C5.72833 3.01979 5.89439 2.99748 6.06292 2.98757C6.71226 2.95287 7.32938 3.07183 7.91676 3.35437C8.20921 3.49564 8.30587 3.45103 8.405 3.14619C8.50909 2.824 8.59584 2.49685 8.6925 2.17218C8.75693 1.95408 8.67762 1.81033 8.47192 1.71863C8.0952 1.55258 7.70857 1.43361 7.30211 1.36918C6.77174 1.28739 6.77174 1.28491 6.76926 0.752056C6.76678 0.00110152 6.76678 0.00110151 6.01335 0.00110151C5.9043 0.00110151 5.79525 -0.00137689 5.6862 0.00110151C5.33427 0.0110151 5.27479 0.072975 5.26487 0.427386C5.25992 0.586003 5.26487 0.744621 5.26239 0.905716C5.25992 1.37661 5.25744 1.36918 4.80637 1.53275C3.71587 1.92929 3.04175 2.67281 2.96988 3.86244C2.90544 4.91576 3.45564 5.62706 4.3206 6.14505C4.85346 6.46476 5.44332 6.65312 6.00839 6.90344C6.22897 7.00009 6.43963 7.11162 6.62303 7.26528C7.1658 7.71387 7.06667 8.45987 6.42228 8.74241C6.07779 8.89359 5.71346 8.93077 5.33922 8.88368C4.76176 8.8118 4.20908 8.66062 3.68861 8.39048C3.38377 8.23186 3.29455 8.27399 3.19045 8.60362C3.10123 8.88863 3.02192 9.17613 2.94261 9.46362C2.83604 9.85025 2.8757 9.94195 3.24498 10.1229C3.71587 10.3509 4.21899 10.4674 4.73202 10.5492C5.13352 10.6136 5.14591 10.6309 5.15087 11.0473C5.15334 11.2357 5.15334 11.4265 5.15582 11.6149C5.1583 11.8528 5.27231 11.9916 5.51767 11.9965C5.79525 12.0015 6.07531 12.0015 6.35289 11.9941C6.5809 11.9891 6.69739 11.8652 6.69739 11.6347C6.69739 11.3769 6.70978 11.1167 6.69986 10.859C6.68747 10.5962 6.80148 10.4624 7.05428 10.393C7.6367 10.2344 8.13238 9.92212 8.51405 9.45619C9.5748 8.16742 9.17083 6.28136 7.66892 5.4511Z"
              fill={props.country === props.code ? '#0063D1' : '#464646'}
            />
          </g>
          <defs>
            <clipPath id="clip0_1853_98">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : props.id === 1 ? (
        //eu flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clipPath="url(#clip0_1856_112)">
            <path
              d="M11.071 10.6972C10.9364 10.3683 10.7994 10.0394 10.6499 9.71544C10.5178 9.42638 10.3484 9.34166 10.0319 9.41392C9.63071 9.50612 9.23699 9.62822 8.83581 9.71045C8.06084 9.87242 7.28338 9.88737 6.51838 9.64566C5.55902 9.34415 5.04819 8.64892 4.73421 7.75185H7.33571C7.54004 7.75185 7.7045 7.58739 7.7045 7.38306V6.56324C7.7045 6.35891 7.54004 6.19445 7.33571 6.19445H4.48503C4.48503 5.96519 4.48253 5.74591 4.48503 5.52663H7.33571C7.54004 5.52663 7.7045 5.36217 7.7045 5.15783V4.33801C7.7045 4.13368 7.54004 3.96922 7.33571 3.96922H4.84385C4.84385 3.95925 4.84385 3.94928 4.84884 3.9443C5.14786 3.26402 5.58393 2.71831 6.2991 2.43424C7.13138 2.10531 7.9811 2.11279 8.8383 2.28971C9.24447 2.37194 9.64317 2.49653 10.0493 2.58873C10.3459 2.65601 10.5178 2.5688 10.6449 2.2922C10.7919 1.97324 10.9265 1.6493 11.061 1.32287C11.1881 1.01637 11.1134 0.799579 10.8243 0.630133C10.752 0.587771 10.6773 0.552885 10.6 0.522983C9.39896 0.0545145 8.16051 -0.119915 6.88219 0.0844167C5.98263 0.228944 5.14288 0.537934 4.41775 1.10109C3.50324 1.80878 2.91017 2.74323 2.5663 3.84213L2.52394 3.96922H1.24312C1.03879 3.96922 0.874329 4.13368 0.874329 4.33801V5.15783C0.874329 5.36217 1.03879 5.52663 1.24312 5.52663H2.25232V6.19445H1.24312C1.03879 6.19445 0.874329 6.35891 0.874329 6.56324V7.38306C0.874329 7.58739 1.03879 7.75185 1.24312 7.75185H2.45915C2.55135 8.05088 2.62859 8.35488 2.74571 8.64145C3.3612 10.1266 4.39283 11.1831 5.94525 11.6865C7.22856 12.1026 8.52183 12.0902 9.8151 11.7338C10.1515 11.6416 10.4854 11.522 10.8044 11.375C11.1134 11.233 11.1931 10.9938 11.071 10.6972Z"
              fill={props.country === props.code ? '#0063D1' : '#464646'}
            />
          </g>
          <defs>
            <clipPath id="clip0_1856_112">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : props.id === 2 ? (
        //IN flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <path
            d="M9.74988 2.7187C9.69881 2.66757 9.63344 2.64204 9.55397 2.64204H8.09665C7.99999 2.17061 7.81817 1.76138 7.55116 1.41481H9.53684C9.61651 1.41481 9.6817 1.38926 9.73296 1.33825C9.78382 1.28714 9.80938 1.22174 9.80938 1.14221V0.272718C9.80938 0.193135 9.78385 0.127851 9.73296 0.076687C9.68164 0.0256419 9.61645 0 9.53678 0H2.446C2.36641 0 2.30113 0.0256419 2.24997 0.0766571C2.19895 0.127822 2.17331 0.193105 2.17331 0.272688V1.40619C2.17331 1.48004 2.20029 1.54395 2.25426 1.59792C2.30824 1.65189 2.37215 1.67887 2.446 1.67887H3.68179C4.88058 1.67887 5.64195 1.9998 5.96578 2.64189H2.446C2.36641 2.64189 2.30104 2.66742 2.24997 2.71855C2.19895 2.76975 2.17331 2.835 2.17331 2.91458V3.78396C2.17331 3.86348 2.19883 3.92886 2.24997 3.97987C2.30113 4.03106 2.36653 4.05653 2.446 4.05653H6.08527C5.96025 4.52256 5.66903 4.87761 5.21159 5.12194C4.75413 5.3663 4.15057 5.48848 3.40054 5.48848H2.446C2.37215 5.48848 2.30824 5.51549 2.25426 5.56943C2.20026 5.62343 2.17331 5.68737 2.17331 5.76116V6.84365C2.17331 6.91762 2.19883 6.98009 2.24997 7.03117C3.34081 8.19022 4.75562 9.81223 6.49423 11.8976C6.54542 11.9659 6.61641 11.9999 6.70733 11.9999H8.36919C8.48862 11.9999 8.57107 11.9489 8.61639 11.8464C8.67319 11.7441 8.66206 11.6476 8.58227 11.5566C6.92339 9.5225 5.61933 7.9998 4.67046 6.98848C5.63637 6.87487 6.42046 6.5623 7.02277 6.05105C7.62498 5.53973 7.9914 4.87483 8.12217 4.05668H9.55397C9.63344 4.05668 9.69884 4.03118 9.74988 3.98002C9.80108 3.929 9.82669 3.86363 9.82669 3.78411V2.91482C9.82669 2.83515 9.80108 2.76986 9.74988 2.7187Z"
            fill={props.country === props.code ? '#0063D1' : '#464646'}
          />
        </svg>
      ) : props.id === 3 ? (
        //GB flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clipPath="url(#clip0_1856_158)">
            <mask
              id="mask0_1856_158"
              //   style="mask-type:luminance"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="12"
              height="12"
            >
              <path d="M0 3.52859e-05H12V12H0V3.52859e-05Z" fill="white" />
            </mask>
            <g mask="url(#mask0_1856_158)">
              <path
                d="M7.59627 12C6.80561 12 6.01727 11.7431 5.37644 11.2767L5.3302 11.2408C4.93952 10.9172 4.42716 10.7693 3.92445 10.8349C3.80843 10.8503 3.69415 10.8768 3.58317 10.914C3.54234 10.9309 3.50123 10.9473 3.45987 10.9629L3.405 10.9836C3.18188 11.085 2.97889 11.2315 2.81457 11.4103L2.4185 11.8413L1.03958 10.5739L1.43565 10.143C1.83739 9.7059 2.34544 9.36854 2.90602 9.16637C3.68496 8.82228 4.2071 8.02304 4.2071 7.1718C4.2071 7.12469 4.2056 7.07767 4.2026 7.03087H1.74296V5.158H3.37369C3.17902 4.70156 3.07751 4.20661 3.07751 3.70397C3.07751 1.66161 4.73911 3.52859e-05 6.78145 3.52859e-05C7.58277 3.52859e-05 8.34564 0.251941 8.98757 0.728494C9.61611 1.19509 10.0736 1.83434 10.3106 2.57717L8.52641 3.14646C8.28332 2.3847 7.58212 1.8729 6.78145 1.8729C5.77181 1.8729 4.95037 2.69431 4.95037 3.70397C4.95037 4.10459 5.07883 4.54774 5.55337 5.158H8.5326V7.03087H6.0775C6.07914 7.07783 6.07996 7.12483 6.07996 7.1718C6.07996 7.8947 5.89106 8.59253 5.53092 9.20959C5.88033 9.34826 6.20652 9.53946 6.49823 9.77658C6.88401 10.0498 7.36548 10.1723 7.82197 10.1126C8.33263 10.0459 8.7943 9.76487 9.0886 9.34161L9.42273 8.86107L10.9604 9.93024L10.6263 10.4108C10.0313 11.2665 9.0976 11.8347 8.06458 11.9697C7.90929 11.9899 7.75286 12 7.59627 12Z"
                fill={props.country === props.code ? '#0063D1' : '#464646'}
              />
            </g>
          </g>
          <defs>
            <clipPath id="clip0_1856_158">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : props.id === 4 ? (
        //BR flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="10"
          viewBox="0 0 12 10"
          fill="none"
        >
          <path
            d="M2.78552 6.1265H1.8078V8.77831H0V1.28248H2.94987C3.83845 1.28248 4.53204 1.48025 5.03344 1.87301C5.53483 2.26577 5.78553 2.82287 5.78553 3.54432C5.78553 4.06521 5.67968 4.49697 5.46798 4.83959C5.25628 5.18221 4.92759 5.46076 4.47633 5.67246L6.04179 8.69753V8.77553H4.10586L2.78552 6.1265ZM1.8078 4.73652H2.94987C3.29248 4.73652 3.55154 4.64739 3.72424 4.46633C3.89694 4.28527 3.98329 4.03457 3.98329 3.71145C3.98329 3.38833 3.89694 3.13485 3.72146 2.95101C3.54597 2.76716 3.2897 2.67524 2.94987 2.67524H1.8078V4.73652ZM10.2229 6.78945C10.2229 6.51926 10.156 6.30477 10.0223 6.15157C9.8886 5.99836 9.65461 5.85073 9.31756 5.71424C8.98052 5.57775 8.67132 5.43848 8.38442 5.29641C8.10029 5.15714 7.85517 4.99279 7.64904 4.80616C7.44291 4.62232 7.28414 4.40505 7.1755 4.16271C7.06686 3.92037 7.01115 3.6251 7.01115 3.27691C7.01115 2.69752 7.20614 2.21841 7.5989 1.84237C7.98887 1.46632 8.51255 1.24905 9.16436 1.18499V0.0986328H9.9833V1.20448C10.61 1.29641 11.1031 1.55546 11.4624 1.97886C11.8217 2.40226 12 2.93986 12 3.59168H10.2591C10.2591 3.23513 10.1894 2.95936 10.0529 2.76716C9.91645 2.57496 9.71868 2.47747 9.4652 2.47747C9.23957 2.47747 9.0613 2.54989 8.93873 2.69195C8.81339 2.83401 8.74932 3.03179 8.74932 3.28248C8.74932 3.53318 8.82174 3.73931 8.96659 3.88694C9.11144 4.03736 9.34264 4.17942 9.66297 4.31313C9.9833 4.44962 10.2841 4.58889 10.5655 4.73374C10.8496 4.87859 11.0919 5.04572 11.2981 5.22956C11.5042 5.41341 11.6658 5.63068 11.7828 5.87859C11.8997 6.1265 11.9582 6.42176 11.9582 6.76995C11.9582 7.3577 11.7716 7.83402 11.4011 8.20728C11.0279 8.57776 10.5209 8.79503 9.88024 8.85631V9.88974H9.0613V8.85074C8.31199 8.77274 7.73818 8.51648 7.33985 8.08193C6.93873 7.64739 6.74096 7.07636 6.74096 6.36605H8.47634C8.47634 6.75603 8.56269 7.05408 8.73539 7.26021C8.90809 7.46355 9.156 7.56661 9.47355 7.56661C9.70754 7.56661 9.89138 7.49698 10.0251 7.3577C10.156 7.22399 10.2229 7.03179 10.2229 6.78945Z"
            fill={props.country === props.code ? '#0063D1' : '#464646'}
          />
        </svg>
      ) : props.id === 5 ? (
        //CA flag
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clipPath="url(#clip0_1856_142)">
            <path
              d="M7.66892 5.4511C7.19802 5.19086 6.69739 4.99259 6.20171 4.78689C5.91421 4.66792 5.63911 4.52913 5.39623 4.33582C4.9179 3.95414 5.0096 3.33454 5.56972 3.08918C5.72833 3.01979 5.89439 2.99748 6.06292 2.98757C6.71226 2.95287 7.32938 3.07183 7.91676 3.35437C8.20921 3.49564 8.30587 3.45103 8.405 3.14619C8.50909 2.824 8.59584 2.49685 8.6925 2.17218C8.75693 1.95408 8.67762 1.81033 8.47192 1.71863C8.0952 1.55258 7.70857 1.43361 7.30211 1.36918C6.77174 1.28739 6.77174 1.28491 6.76926 0.752056C6.76678 0.00110152 6.76678 0.00110151 6.01335 0.00110151C5.9043 0.00110151 5.79525 -0.00137689 5.6862 0.00110151C5.33427 0.0110151 5.27479 0.072975 5.26487 0.427386C5.25992 0.586003 5.26487 0.744621 5.26239 0.905716C5.25992 1.37661 5.25744 1.36918 4.80637 1.53275C3.71587 1.92929 3.04175 2.67281 2.96988 3.86244C2.90544 4.91576 3.45564 5.62706 4.3206 6.14505C4.85346 6.46476 5.44332 6.65312 6.00839 6.90344C6.22897 7.00009 6.43963 7.11162 6.62303 7.26528C7.1658 7.71387 7.06667 8.45987 6.42228 8.74241C6.07779 8.89359 5.71346 8.93077 5.33922 8.88368C4.76176 8.8118 4.20908 8.66062 3.68861 8.39048C3.38377 8.23186 3.29455 8.27399 3.19045 8.60362C3.10123 8.88863 3.02192 9.17613 2.94261 9.46362C2.83604 9.85025 2.8757 9.94195 3.24498 10.1229C3.71587 10.3509 4.21899 10.4674 4.73202 10.5492C5.13352 10.6136 5.14591 10.6309 5.15087 11.0473C5.15334 11.2357 5.15334 11.4265 5.15582 11.6149C5.1583 11.8528 5.27231 11.9916 5.51767 11.9965C5.79525 12.0015 6.07531 12.0015 6.35289 11.9941C6.5809 11.9891 6.69739 11.8652 6.69739 11.6347C6.69739 11.3769 6.70978 11.1167 6.69986 10.859C6.68747 10.5962 6.80148 10.4624 7.05428 10.393C7.6367 10.2344 8.13238 9.92212 8.51405 9.45619C9.5748 8.16742 9.17083 6.28136 7.66892 5.4511Z"
              fill={props.country === props.code ? '#0063D1' : '#464646'}
            />
          </g>
          <defs>
            <clipPath id="clip0_1856_142">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        ''
      )}
    </>
  );
};
export default FlagSvg;
