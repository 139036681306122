import React from 'react';
import { breadCrumbType, storageKey } from '@/Interface/interface';
import breadCrumbConst from '@/utils/constants';

export function setCompareDeviceBreadCrumb(
  setBreadCrumbs: React.Dispatch<
    React.SetStateAction<breadCrumbType[] | undefined>
  >,
  deviceName: any,
  setDeviceName?: any,
) {
  const compareDeviceSlug = sessionStorage.getItem(storageKey.compareRouteLink);
  if (!compareDeviceSlug) {
    setBreadCrumbs([
      {
        ...breadCrumbConst.comparePhones,
        isActive: true,
      },
    ]);
    if (setDeviceName) {
      setDeviceName(['']);
    }
    return;
  }

  const compareDeviceName = Array.isArray(deviceName)
    ? deviceName.join(' vs ')
    : deviceName;

  setBreadCrumbs([
    {
      ...breadCrumbConst.comparePhones,
      isActive: false,
    },
    {
      hrefLink: `/${compareDeviceSlug}`,
      title: compareDeviceName,
      isActive: true,
    },
  ]);
}

export function getCurrentMonthAndYear() {
  const date = new Date();
  const month = date.toLocaleString('en-US', { month: 'long' });
  const year = date.getFullYear();
  return `${month} ${year}`; // Output: June 2024
}
