import { Box, Button, Container, Link } from '@mui/material';
import { useState, useContext, RefObject, useEffect } from 'react';
import HeaderSearch from './headerSearch';
import SearchIcon from '@mui/icons-material/Search';
import webLogo from '../Assets/Images/web_icon.png';
import Image from 'next/image';
import CloseIcon from '@mui/icons-material/Close';
import { ROUTE, storageKey } from '../Interface/interface';
import { useRouter } from 'next/router';
import { Context } from '@/pages/_app';
import SearchModal from './Modal/searchModal';
import CurrencyDropDown from './currencyDropDown';
import { setCompareDeviceBreadCrumb } from '@/utils/common-service';

const Header = (props: {
  myRef: RefObject<HTMLDivElement>;
  countryCode: string;
}) => {
  const {
    setTagArr,
    setSearchQuery,
    setInputValue,
    setBreadCrumbs,
    scrollTargetRef,
    currencyDropDownValue,
    deviceName,
    setDeviceName,
  } = useContext(Context);
  const router = useRouter();
  const [searchToggle, setSearchToggle] = useState(false);
  const [isMenuShow, setIsMenuShow] = useState(false);
  const headerMenu = [
    { id: 1, menutitle: 'home', menuURL: `` },
    { id: 2, menutitle: 'phones', menuURL: `${ROUTE.MOBILE_PHONES}` },
    {
      id: 3,
      menutitle: 'popular phones',
      menuURL: `${ROUTE.POPULAR_PHONES}`,
    },
    {
      id: 4,
      menutitle: 'Upcoming phones',
      menuURL: `${ROUTE.UPCOMING_PHONES}`,
    },
    { id: 5, menutitle: 'Compare phones', menuURL: `${ROUTE.COMPARE}` },
    {
      id: 6,
      menutitle: 'upcoming technologies',
      menuURL: `/${ROUTE.UPCOMING_TECHNOLOGIES}/`,
    },
    { id: 7, menutitle: 'blog', menuURL: `${process.env.BASE_URL}blog/` },
  ];

  function getHeaderHref(menuURL: string) {
    const origin = typeof window !== 'undefined' ? window.location.origin : '';
    const currencyValue = currencyDropDownValue?.toLowerCase();

    const url = menuURL ? `${menuURL}/` : '';
    return `${origin}/${url}`;
  }
  return (
    <Box className="header" ref={scrollTargetRef}>
      <Container>
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
          py={{ md: 3, xs: '10px' }}
        >
          <Box>
            <Link href="/">
              <Image  src={webLogo} priority alt="TechSpecs Logo" height="29" />
            </Link>
          </Box>
          <Box className="d-flex align-items-center">
            <Box className="header-search d-md-block d-none">
              <HeaderSearch setOpen={null} countryCode={props.countryCode} />
            </Box>
            <Button
              className="menu-toggle cursor-pointer text-black d-block d-md-none position-relative"
              onClick={() => setIsMenuShow(true)}
              aria-label="search"
            >
              <span></span>
            </Button>
            <Box
              className="search-toggle cursor-pointer d-flex justify-content-center align-items-center d-md-none"
              onClick={() => {
                if (searchToggle) {
                  setSearchToggle(false);
                } else {
                  setSearchToggle(true);
                }
              }}
            >
              {searchToggle === true ? <CloseIcon /> : <SearchIcon />}
            </Box>
          </Box>
        </Box>
      </Container>
      <Box
        className={` header-nav nav ${isMenuShow ? 'menu-open' : ''}`}
        // onClick={() => setIsMenuShow(false)}
      >
        <Box
          className="header-nav-wrapper bg-blue"
          pt={1.88}
          pb={1.8}
          ref={props?.myRef}
        >
          <Button
            className="menu-close-toggle text-white cursor-pointer d-block d-md-none"
            onClick={() => setIsMenuShow(false)}
          >
            <CloseIcon />
          </Button>
          <Box className="container d-md-flex" justifyContent="space-between">
            <ul className="list-unstyled m-0 d-md-flex d-block">
              {headerMenu.map((data, i) => {
                return (
                  <li
                    key={i}
                    className={`menu-item ${
                      router.pathname === `${data.menuURL}`
                        ? 'active'
                        : (router.pathname ===
                              `/${ROUTE.POPULAR_PHONES}/[[...popularId]]` &&
                              data.menuURL === `${ROUTE.POPULAR_PHONES}`) ||
                            (router.pathname ===
                              `/${ROUTE.UPCOMING_PHONES}/[[...upcomingId]]` &&
                              data.menuURL === `${ROUTE.UPCOMING_PHONES}`) ||
                            (router.pathname ===
                              `/${ROUTE.MOBILE_PHONES}/[[...phonesId]]` &&
                              data.menuURL === `${ROUTE.MOBILE_PHONES}`) ||
                            (router.pathname ===
                              `/${ROUTE.COMPARE}/[[...compareDevice]]` &&
                              data.menuURL === `${ROUTE.COMPARE}`) ||
                            (router.pathname === '/' && data.menuURL === '')
                          ? 'active'
                          : ''
                    }`}
                    onClick={() => {
                      setIsMenuShow(false);
                      setTagArr([]);
                      setSearchQuery('');
                      setInputValue('');
                      if (data.menuURL === `/${ROUTE.COMPARE}`) {
                        sessionStorage.setItem(storageKey.compareRouteLink, '');
                        setCompareDeviceBreadCrumb(
                          setBreadCrumbs,
                          deviceName,
                          setDeviceName,
                        );
                        window.history.replaceState(
                          window.history.state,
                          document.title,
                          `${window.location.origin}/${ROUTE.COMPARE}/`,
                        );
                      }
                    }}
                  >
                    {data.menuURL !== `/${ROUTE.UPCOMING_TECHNOLOGIES}/` &&
                    data.menuURL !== `${process.env.BASE_URL}blog/` ? (
                      <Link
                        // locale={currencyDropDownValue.toLowerCase()}
                        href={getHeaderHref(data.menuURL)}
                        className="header-nav-link text-white text-uppercase font-semibold position-relative"
                      >
                        {data.menutitle}
                      </Link>
                    ) : (
                      <a
                        href={`${data.menuURL}`}
                        className="header-nav-link text-white text-uppercase font-semibold position-relative"
                      >
                        {data.menutitle}
                      </a>
                    )}
                  </li>
                );
              })}
            </ul>
            <CurrencyDropDown
              setIsMenuShow={setIsMenuShow}
              countryCode={props.countryCode}
            />
          </Box>
        </Box>
      </Box>
      <SearchModal
        open={searchToggle}
        setOpen={setSearchToggle}
        countryCode={props.countryCode}
      />
    </Box>
  );
};

export default Header;
