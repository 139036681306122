import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { currencyLocaleType } from '@/Configuration/array';
import { Context } from '@/pages/_app';
import ErrorBoundary from '@/pages/ErrorBoundary';
import FlagSvg from './flagSvg';
import Cookies from 'js-cookie';
import { getDevicesAPI, getSearchDevicesAPI } from '@/Configuration/API';
import { CountryList } from '@/utils/Country-list';
import { GetServerSidePropsContext } from 'next';

export let headerReqCountryCode: { country: string } = {
  country: '',
};
function CurrencyDropDown(props: {
  setIsMenuShow: Dispatch<SetStateAction<boolean>>;
  countryCode: string;
}) {
  const { countryCode } = props;

  const {
    currencyDropDownValue,
    setCurrencyDropDownValue,
    setCurrencyDropDownChange,
    tagArr,
    setTagArr,
  } = useContext(Context);

  const handleChange = (event: SelectChangeEvent) => {
    let newTagArr = tagArr.filter((res) => res.label !== 'price');
    setTagArr(newTagArr);
    setCurrencyDropDownChange(true);
    setCurrencyDropDownValue(event.target.value);
    Cookies.set('CountryCode', event.target.value, { expires: 7 });
    sessionStorage.setItem('currencyCode', event.target.value);
    setTimeout(() => {
      setCurrencyDropDownChange(false);
    }, 100);
    props.setIsMenuShow(false);
  };

  const getCountryCode = () => {
    if (!Cookies.get('CountryCode')) {
      const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // @ts-ignore
      return CountryList[timeZone] || 'US';
    } else {
      return Cookies.get('CountryCode');
    }
  };

  useEffect(() => {
    if (!Cookies.get('CountryCode')) {
      let newTagArr = tagArr.filter((res) => res.label !== 'price');
      setTagArr(newTagArr);
      const countryCode = getCountryCode();
      setCurrencyDropDownChange(true);
      setCurrencyDropDownValue(countryCode);
      Cookies.set('CountryCode', countryCode, { expires: 7 });
      sessionStorage.setItem('currencyCode', countryCode);
      setTimeout(() => {
        setCurrencyDropDownChange(false);
      }, 100);
    } else {
      setCurrencyDropDownChange(false);
    }
  }, []);

  return (
    <ErrorBoundary>
      <Box padding={{ md: 0, xs: '15px' }}>
        <FormControl>
          <Select
            className="select-test"
            id="country-select-box"
            value={
              countryCode !== currencyDropDownValue
                ? currencyDropDownValue
                : countryCode
            }
            onChange={handleChange}
            onFocusCapture={(e) => {
              if (e.target.children[0]?.tagName === 'svg') {
                document.body.className = 'overflow-auto';
              }
            }}
            sx={{
              '& .MuiOutlinedInput-input': {
                padding: '4px 10px',
                backgroundColor: 'white',
                display: 'flex !important',
                alignItems: 'center !important',
                color: 'var(--blue)',
                fontWeight: '600',
              },
              '& .MuiList-root': {
                display: 'flex !important',
                alignItems: 'center !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none',
              },
              '& .MuiSvgIcon-root': {
                right: '4px',
              },
            }}
          >
            {Object.keys(currencyLocaleType)?.length &&
              Object.keys(currencyLocaleType)?.map((res, i) => (
                <MenuItem
                  aria-label="currency"
                  className="country-item"
                  value={currencyLocaleType[res]?.country}
                  key={i}
                  sx={{
                    color:
                      currencyLocaleType[res]?.country === currencyDropDownValue
                        ? '#0063D1'
                        : '',
                    fontSize: '14px',
                    padding: '5px 10px',
                    fontWeight: '600',
                    '& > svg': {
                      minWidth: '12px',
                      marginRight: '5px',
                    },
                  }}
                >
                  <FlagSvg
                    id={i}
                    country={currencyLocaleType[res]?.country}
                    code={currencyDropDownValue}
                  />
                  &nbsp;{res}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>
    </ErrorBoundary>
  );
}

export default CurrencyDropDown;
