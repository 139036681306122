import LatestBlogs from './latestBlogs';
import { Box, Grid, Typography, Link } from '@mui/material';
import Image from 'next/image';
import FooterLogo from '../Assets/Images/web_icon.png';
import FacebookIcon from '../Assets/Images/facebook.svg';
import PintrestIcon from '../Assets/Images/pintrest.svg';
import TwitterIcon from '../Assets/Images/twitter.svg';
import LinkedInIcon from '../Assets/Images/linkedin.svg';
import mailIcon from '../Assets/Images/envelop.svg';
import { useRouter } from 'next/router';
import { ROUTE } from '@/Interface/interface';
import ScrollToTopButton from './ScrollToTopButton';

const Footer = ({ blogs }: any) => {
  const router = useRouter();
  const isShowLatestBlog =
    router.pathname !== `/${ROUTE.TERMS}` &&
    router.pathname !== `/${ROUTE.PRIVACY_POLICY}` &&
    router.pathname !== ROUTE.ERROR_PAGE &&
    router.pathname !== `/${ROUTE.CONTACT_US}` &&
    router.pathname !== `/${ROUTE.ABOUT_US}`;
  return (
    <>
      {isShowLatestBlog ? <LatestBlogs blogs={blogs} /> : ''}
      <Box className="footer section-top-gapping">
        <Box
          className="footer-top bg-lightgray"
          pt={3.125}
          pb={0.625}
          borderBottom={'1px solid #EBEBEB'}
          sx={{
            height: {
              md: 'auto',
              lg: '229px',
            },
          }}
        >
          <Grid
            container
            className="container"
            sx={{ justifyContent: 'space-between' }}
          >
            <Grid item xs={12} sm={5.5} md={8} lg={8.5} className="">
              <Box className="" mb={1.875}>
                <Link href="/">
                  <Image
                    src={FooterLogo}
                    alt="footer-logo"
                    width="256"
                    style={{ height: 'auto' }}
                    objectFit="cover"
                  />
                </Link>
              </Box>
              <Typography
                maxWidth={{ xs: '100%', sm: 500 }}
                mb={1.875}
                sx={{
                  color: '#242424',
                  fontSize: '14px',
                  lineHeight: '21px',
                }}
              >
                Welcome to our mobile phone technical specification hub! We're
                dedicated to providing tech specs for smartphones, latest
                pricing update, and mobile phone comparisons with detailed
                specification. Our mission is to simplify your smartphone
                decision-making process by providing all the essential
                information you need in one place. Let us be your trusted source
                for making smart mobile choices.
              </Typography>
            </Grid>
            <Grid
              item
              xs={4.5}
              sm={2.5}
              md={1.5}
              lg={1.5}
              sx={{
                justifyContent: {
                  xs: 'start',
                  sm: 'center',
                },
              }}
              display={'flex'}
              mt={{ xs: 0.6, sm: 0 }}
              mb={2.5}
            >
              <Box
                className=""
                mr={3}
                sx={{
                  '& .about-list-box': {
                    padding: '2px 0px',
                  },
                }}
              >
                <Box className="d-flex align-items-center" mb={1.2}>
                  <Typography
                    component="h3"
                    sx={{
                      lineHeight: '24px',
                      fontFamily: 'Poppins!important',
                      fontSize: '22px!important',
                      fontWeight: '600!important',
                      textTransform: 'capitalize!important',
                    }}
                  >
                    About
                  </Typography>
                </Box>
                <Box
                  className="d-flex align-items-center about-list-box"
                  sx={{
                    '& .about-text': {
                      fontFamily: 'Poppins!important',
                      fontSize: '14px!important',
                      lineHeight: '28px!important',
                    },
                  }}
                >
                  <Link
                    href={`/${ROUTE.ABOUT_US}/`}
                    className="blog-about-link"
                  >
                    <Typography className="fs-14 about-text">
                      About Us
                    </Typography>
                  </Link>
                </Box>
                <Box className="d-flex align-items-center about-list-box">
                  <Link
                    href={`/${ROUTE.CONTACT_US}/`}
                    className="blog-about-link"
                  >
                    <Typography className="fs-14 about-text">
                      Contact Us
                    </Typography>
                  </Link>
                </Box>
                <Box className="d-flex align-items-center about-list-box">
                  <Link href={`/blog/`} className="blog-about-link">
                    <Typography className="fs-14 about-text">News</Typography>
                  </Link>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={7.5}
              sm={3.5}
              md={2.5}
              lg={2}
              className="justify-content-start justify-content-sm-end"
              display={'flex'}
              mb={2.5}
            >
              <Box className="" mr={0.375}>
                <Box
                  className="d-flex align-items-center mail-link"
                  mb={{ xs: 0.6, sm: '14px' }}
                >
                  <Image loading="lazy" src={mailIcon} alt="email-icon" />
                  <a
                    href="mailto:contact@techspecs.info"
                    className="text-decoration-none text-black ml-1"
                  >
                    &nbsp;contact@techspecs.info
                    {/* info@weetechsolution.com */}
                  </a>
                </Box>
                <Typography className="follow-us-text" component="h3">
                  Follow Us
                </Typography>

                <ul className="social-links list-unstyled d-flex m-0 p-0 justify-content-start">
                  <li className="item" style={{ background: '#3b5998' }}>
                    <Link
                      href="https://www.facebook.com/TechSpecsInfo/"
                      target="_blank"
                      aria-label="Facebook"
                      className="social-link d-block text-center"
                    >
                      <Image
                        loading="lazy"
                        src={FacebookIcon}
                        alt="Facebook icon"
                      />
                    </Link>
                  </li>
                  <li className="item">
                    <Link
                      href="https://www.linkedin.com/company/techspecs-info/"
                      target="_blank"
                      aria-label="Linkedin"
                      className="social-link d-block text-center "
                    >
                      <Image
                        loading="lazy"
                        src={LinkedInIcon}
                        alt="linkedin icon"
                        style={{ height: '24px' }}
                      />
                    </Link>
                  </li>
                  {/* <li className="item">
                    <Link
                      href=""
                      target="_blank"
                      aria-label="Instagram"
                      className="social-link d-block text-center rounded-1"
                    >
                      <Image  loading="lazy" src={InstagramIcon} alt="instagram icon" />
                    </Link>
                  </li> */}
                  <li style={{ background: '#D50012' }}>
                    <Link
                      href="https://www.pinterest.com/techspecsinfo/"
                      target="_blank"
                      aria-label="Pintrest"
                      className="social-link d-block text-center "
                    >
                      <Image
                        loading="lazy"
                        src={PintrestIcon}
                        alt="Pintrest icon"
                      />
                    </Link>
                  </li>
                  <li className="item">
                    <Link
                      href="https://x.com/Tech_Specs_Info"
                      target="_blank"
                      aria-label="Twitter"
                      className="social-link d-block text-center "
                    >
                      <Image
                        loading="lazy"
                        src={TwitterIcon}
                        alt="twitter icon"
                      />
                    </Link>
                  </li>
                </ul>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box className="site-info" py={{ xs: 1, md: 3 }}>
          <Grid container className="container align-items-center">
            <Grid
              item
              xs={12}
              sm={6}
              className="copy-write text-sm-start text-center"
              fontWeight={500}
            >
              © {new Date().getFullYear()} TechSpecs.Info
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                '& .privacy-terms': {
                  fontFamily: 'Poppins!important',
                  fontSize: '14px!important',
                  fontWeight: 500,
                  lineHeight: '21px!important',
                },
              }}
            >
              <ul className="list-unstyled d-flex justify-content-center justify-content-sm-end m-0">
                <li className="items">
                  <Link
                    className="text-decoration-none text-black privacy-terms"
                    href={`/${ROUTE.PRIVACY_POLICY}/`}
                  >
                    Privacy
                  </Link>
                </li>
                <li className="items">
                  <Link
                    className="text-decoration-none text-black privacy-terms"
                    href={`/${ROUTE.TERMS}/`}
                  >
                    &nbsp;&nbsp;&nbsp;Terms &amp; conditions
                  </Link>
                </li>
                <li className="items">
                  <ScrollToTopButton />
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
